






































import { VueConstructor } from 'vue'
import { Component, Mixins, Prop, Ref, Vue } from 'vue-property-decorator'
import _throttle from 'lodash/throttle'

import { ComponentsStructureConfig, StructureConfigurable } from '../../../../support/mixins'
import { UseListeners } from '../../../../modules/mixins/UseListeners'
import { ModuleContent } from '../../../../contexts'

import { MegaMenuProps } from './MegaMenu.contracts'
import {
  DIRECTIONS,
  DirectionsType,
  DISABLED_CLASS,
  MEGA_MENU_COMPONENT_KEY,
  MEGA_MENU_DEFAULT_CONFIG,
  megaMenuModuleUiRegistry,
  PAGINATION_BUTTON_SELECTOR,
  TRANSLATE_ANIMATION_DURATION
} from './MegaMenu.config'

import MegaMenuPagination from './partials/MegaMenuPagination.vue'
import MegaMenuNavigation from './partials/MegaMenuNavigation.vue'

/**
 * MegaMenu organism for Navigation module.
 *
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl> (original)
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl> (edited)
 */
@Component<MegaMenu>({
  name: 'MegaMenu',
  components: {
    MegaMenuNavigation,
    MegaMenuPagination
  },
  created (): void {
    this.config = this.getComponentConfig(MEGA_MENU_COMPONENT_KEY, { ...MEGA_MENU_DEFAULT_CONFIG })
  },
  mounted (): void {
    if (this.hasPagination) {
      this.checkIsEnoughColumnsToPaginate()
      this.registerListeners()
    }
  }
})
export class MegaMenu extends Mixins(Vue, StructureConfigurable, UseListeners) {
  /**
   * @see MegaMenuProps.navigations
   */
  @Prop({
    type: Array,
    required: true
  })
  public navigations!: MegaMenuProps['navigations']

  /**
   * @see MegaMenuProps.additionalCategoryLink
   */
  @Prop({
    type: Object,
    required: false,
    default: {}
  })
  public additionalCategoryLink?: MegaMenuProps['additionalCategoryLink']

  /**
   * @see MegaMenuProps.additionalClass
   */
  @Prop({
    type: String,
    required: false,
    default: ''
  })
  public additionalClass?: MegaMenuProps['additionalClass']

  /**
   * @see MegaMenuProps.additionalClass
   */
  @Prop({
    type: Object,
    required: false,
    default: null
  })
  public snippet?: MegaMenuProps['snippet']

  /**
   * Navigation items container ref.
   */
  @Ref('navItemsContainerRef')
  public readonly navItemsContainerRef?: HTMLDivElement

  /**
   * Navigation pagination ref.
   */
  @Ref('megaMenuPaginationRef')
  public readonly megaMenuPaginationRef?: Vue

  /**
   * Determines navigation current page.
   */
  public currentPage: number = 1

  /**
   * Determines navigation translateX value
   */
  public scrolledList: number = 0

  /**
   * Determines component config.
   */
  public config: ComponentsStructureConfig = {}

  /**
   * Determines megamenu has enough columns for pagination.
   */
  public hasEnoughColumns: boolean = false

  /**
   * Determines whether menu has additional class.
   */
  public get extraClasses (): string {
    if (!this.additionalClass) {
      return ''
    }

    return this.additionalClass.split(',').join(' ')
  }

  /**
   * Determines whether menu has additional content.
   */
  public get hasAdditionalContainer (): boolean {
    return this.getConfigProperty('hasAdditionalContainer')
  }

  /**
   * Determines whether menu has additional content.
   */
  public get hasAdditionalContent (): boolean {
    return this.getConfigProperty('hasAdditionalContent')
  }

  /**
   * Determines whether menu has additional header.
   */
  public get hasColumnAdditionalHeaderOnBottom (): boolean {
    return this.getConfigProperty('hasColumnAdditionalHeaderOnBottom')
  }

  /**
   * Determines whether menu has pagination.
   */
  public get hasPagination (): boolean {
    return this.getConfigProperty('hasPagination')
  }

  /**
   * Determines current pagination page.
   */
  public get page (): number {
    return this.currentPage
  }

  /**
   * Determines is additional category link defined.
   */
  public get showAdditionalLink (): boolean {
    return !!this.additionalCategoryLink && !!this.additionalCategoryLink.label
  }

  public get hasSnippets (): boolean {
    return !!this.snippet && this.snippet.hasOwnProperty('modules') &&
      Object.values(this.snippet.modules).length > 0
  }

  public get snippetModules (): ModuleContent[] {
    if (typeof this.snippet === 'undefined' || !this.hasSnippets) {
      return []
    }

    return Object.values(this.snippet.modules)
  }

  public getModule (type: string): VueConstructor {
    return megaMenuModuleUiRegistry[type]
  }

  /**
   * Determines whether menu category links are headings.
   */
  public get navCategoryItemAsHeading (): boolean {
    return this.getConfigProperty('navCategoryItemAsHeading')
  }

  /**
   * Determines megamenu has enough columns for pagination.
   */
  public checkIsEnoughColumnsToPaginate (): void {
    if (!this.navItemsContainerRef || !this.navItemsContainerRef.parentElement) {
      this.hasEnoughColumns = false
      return
    }

    const lastSlide: Element | undefined = [...this.navItemsContainerRef.children].at(-1)
    if (!lastSlide) {
      this.hasEnoughColumns = false
      return
    }

    const lastSlideRect = lastSlide.getBoundingClientRect()
    const lastSlideRightEdge = (lastSlideRect.x + lastSlideRect.width)

    const slidesContainerRect = this.navItemsContainerRef.parentElement.getBoundingClientRect()
    const slidesContainerRightEdge = slidesContainerRect.x + slidesContainerRect.width

    this.hasEnoughColumns = lastSlideRightEdge > slidesContainerRightEdge
  }

  public setPage (page: number): void {
    if (page < 1) {
      this.currentPage = 1
      return
    }

    this.setNavItemsTranslate(page > this.currentPage ? DIRECTIONS.INCREMENT : DIRECTIONS.DECREMENT)

    this.currentPage = page
  }

  public findFirstHiddenElement (
    direction: string,
    parent: HTMLElement
  ): HTMLElement | undefined {
    if (!this.navItemsContainerRef) {
      return
    }

    let firstNotShowedChild: HTMLElement | undefined
    const navItems = (Array.from(this.navItemsContainerRef.children) as HTMLElement[])
    const parentRect = parent.getBoundingClientRect()
    const parentContainerRightEdge = parentRect.x + parentRect.width

    if (direction === DIRECTIONS.INCREMENT) {
      firstNotShowedChild = navItems.find(
        (child) => {
          const childRect = child.getBoundingClientRect()
          return (childRect.x + childRect.width) > parentContainerRightEdge
        })
    } else {
      firstNotShowedChild = navItems.filter(
        (child) => {
          const childRect = child.getBoundingClientRect()
          return childRect.x < parentRect.x
        }).at(-1)
    }

    return firstNotShowedChild
  }

  public disablePaginationButton (megaMenuContainerEdges: DirectionsType, direction: string): void {
    if (!this.navItemsContainerRef || !this.megaMenuPaginationRef || !this.megaMenuPaginationRef.$el) {
      return
    }

    const children = [...this.navItemsContainerRef.children]

    const lastChild = children.at(-1) as HTMLElement
    const lastChildXPosition = lastChild.getBoundingClientRect().x

    const firstChild = children.at(0) as HTMLElement
    const firstChildXPosition = firstChild.getBoundingClientRect().x

    const paginationButtons = Array.from(this.megaMenuPaginationRef.$el.querySelectorAll(
      PAGINATION_BUTTON_SELECTOR)) as HTMLElement[]
    paginationButtons.forEach((button) => button.classList.remove(DISABLED_CLASS))

    if (direction === DIRECTIONS.INCREMENT) {
      const isEqualToRightSide = Math.floor(megaMenuContainerEdges.right) === Math.floor(
        lastChildXPosition + lastChild.offsetWidth)
      if (!isEqualToRightSide) {
        return
      }

      paginationButtons[1].classList.add(DISABLED_CLASS)
      return
    }

    const isEqualToLeftSide = Math.floor(firstChildXPosition) === Math.floor(megaMenuContainerEdges.left)
    if (!isEqualToLeftSide) {
      return
    }

    paginationButtons[0].classList.add(DISABLED_CLASS)
  }

  public setNavItemsTranslate (direction: string): void {
    if (!this.navItemsContainerRef || this.navItemsContainerRef.children.length === 0) {
      return
    }

    const slidesContainer = this.navItemsContainerRef.parentElement
    if (!slidesContainer) {
      return
    }

    const firstNotShowedElement = this.findFirstHiddenElement(direction, slidesContainer)

    if (!firstNotShowedElement) {
      return
    }

    const slidesContainerRect = slidesContainer.getBoundingClientRect()
    const firstNotShowedElementRect = firstNotShowedElement.getBoundingClientRect()

    const megaMenuContainerEdges = {
      left: slidesContainerRect.x,
      right: slidesContainerRect.x + slidesContainerRect.width
    }

    let translationValue: number | undefined
    if (direction === DIRECTIONS.INCREMENT) {
      translationValue = ((firstNotShowedElementRect.x + firstNotShowedElement.offsetWidth) - megaMenuContainerEdges.right) * -1
    } else {
      translationValue = megaMenuContainerEdges.left - firstNotShowedElementRect.x
    }

    this.scrolledList += translationValue
    this.navItemsContainerRef.style.transform = `translateX(${this.scrolledList}px)`

    setTimeout(() => {
      this.disablePaginationButton(megaMenuContainerEdges, direction)
    }, TRANSLATE_ANIMATION_DURATION)
  }

  public onResize (): void {
    if (!this.navItemsContainerRef || !this.megaMenuPaginationRef) {
      return
    }

    this.scrolledList = 0
    this.navItemsContainerRef.style.transform = `translateX(${this.scrolledList}px)`
    this.checkIsEnoughColumnsToPaginate()

    const paginationButtons = Array.from(this.megaMenuPaginationRef.$el.querySelectorAll(
      PAGINATION_BUTTON_SELECTOR)) as HTMLElement[]
    if (paginationButtons.length > 0) {
      paginationButtons[0].classList.add(DISABLED_CLASS)
    }
  }

  public registerListeners (): void {
    this.addEventListener('resize', _throttle(this.onResize, 100))
  }
}

export default MegaMenu
