var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"Navbar__mobile"},[(_vm.shouldHideMobileNav && !_vm.isPageWithoutMobileNav)?_c('div',{ref:"navigation",staticClass:"brand-wrapper",class:{'--withTopbar': _vm.hasTopBar }},[_c('UiLink',{attrs:{"title":_vm.logoLink && _vm.logoLink.label || '',"target":_vm.logoLink? _vm.logoLink.target : '',"isDisabled":_vm.logoLink && !_vm.logoLink.target}},[_c('UiImage',_vm._b({},'UiImage',Object.assign({}, _vm.brandImage),false))],1)],1):_vm._e(),(_vm.mainLinks)?_c('div',{staticClass:"sidebar-menu",class:{ '--open': _vm.isMenuOpen}},[(_vm.withOpenSearchType)?_c('div',{staticClass:"brand-wrapper"},[_c('UiLink',{attrs:{"title":_vm.logoLink && _vm.logoLink.label || '',"target":_vm.logoLink? _vm.logoLink.target : '',"isDisabled":_vm.logoLink && !_vm.logoLink.target}},[_c('UiImage',_vm._b({},'UiImage',Object.assign({}, _vm.brandImage),false))],1)],1):_vm._e(),(_vm.withOpenSearchType)?_c('div',{staticClass:"search"},[_c('div',{staticClass:"container"},[_c('SearchResults',{attrs:{"isOpen":false},on:{"onDestroy":_vm.onDestroy}})],1)]):_vm._e(),_c('UiNav',_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(ref){
var element = ref.element;
var index = ref.index;
return [_c('div',{staticClass:"container"},[_c('div',{staticClass:"sidebar-item",on:{"click":function($event){_vm.hasChildren(element) ? null : _vm.hideBackdrop}}},[_c('UiLink',_vm._b({staticClass:"sidebar-item__link"},'UiLink',{ target: element.target, label: element.label, tabTarget: element.tabTarget },false),[_vm._v(" "+_vm._s(element.label)+" "),(element)?_c('UiIcon',{attrs:{"width":"12","height":"16","name":element.icon}}):_vm._e()],1),(_vm.hasChildren(element))?_c('label',{attrs:{"for":'open-menu-' + index}}):_vm._e(),(_vm.hasChildren(element))?_c('input',{attrs:{"type":"checkbox","id":'open-menu-' + index,"name":'open-menu-' + index}}):_vm._e(),(_vm.hasChildren(element))?_c('div',{staticClass:"sidebar-item-menu"},[_c('div',{staticClass:"nested-item-info"},[_c('label',{attrs:{"for":'open-menu-' + index}}),_c('input',{attrs:{"type":"checkbox","id":'open-menu-' + index,"name":'open-menu-' + index}}),_c('span',[_vm._v(" "+_vm._s(element.label)+" ")])]),_vm._t("submenu",null,null,{ link: element })],2):_vm._e()],1)])]}}],null,true)},'UiNav',{ links: _vm.mainLinks },false)),(_vm.hasExternalLinks)?_c('UiNav',_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(ref){
var element = ref.element;
return [_c('div',{staticClass:"container"},[_c('UiLink',_vm._b({staticClass:"sidebar-item__link"},'UiLink',{ target: element.target, label: element.label, tabTarget: element.tabTarget },false),[_vm._v(" "+_vm._s(element.label)+" ")])],1)]}}],null,false,1220424523)},'UiNav',{ links: _vm.externalLinks },false)):_vm._e(),(_vm.$slots.infobar)?_c('div',{staticClass:"infobar"},[_c('div',{staticClass:"container"},[_vm._t("infobar")],2)]):_vm._e()],1):_vm._e(),_c('div',{staticClass:"search-container",class:{ '--open': _vm.selectedMenuItem === 'search' }},[_c('SearchResults',{attrs:{"isOpen":_vm.isSearchOpen},on:{"update:isOpen":function($event){_vm.isSearchOpen=$event},"update:is-open":function($event){_vm.isSearchOpen=$event},"onDestroy":_vm.onDestroy}})],1),_c('div',{ref:"mobileMenuWrapper",staticClass:"menu-wrapper"},[_c('UiList',_vm._b({staticClass:"menu-wrapper-list",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var element = ref.element;
return [_c('button',{staticClass:"menu-item",attrs:{"id":'menu-item-' + element.id,"disabled":_vm.isWaitingForAuth && _vm.accountLinkId===element.id},on:{"click":function($event){return _vm.onMenuItemSelect(element.id)}}},[(_vm.isWaitingForAuth && _vm.accountLinkId===element.id)?_c('Loader'):_c('UiIcon',{attrs:{"name":_vm.activeIcon(element)}}),(_vm.showBadge(element))?_c('span',{staticClass:"item-badge"},[_vm._v(_vm._s(_vm.constructBadge(element)))]):_vm._e(),_c('small',{staticClass:"label menu-item-text"},[_vm._v(" "+_vm._s(_vm.$t(("modules.Navbar.menuLabels." + (element.label))))+" ")])],1)]}}])},'UiList',{ elements: _vm.constructBottomNav() },false))],1)])}
var staticRenderFns = []

export { render, staticRenderFns }