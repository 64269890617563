




















import { AnyObject } from '@movecloser/front-core'
import { Component } from 'vue-property-decorator'

import { ImageProps } from '../../../dsl/atoms/Image'
import { toImageProps } from '../../../front/shared/support'

import { AbstractModuleUi } from '../../abstract'

import { ImageModule } from '../Image.contracts'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 * @author Filip Rurak <filip.rurak@movecloser.pl> (edited)
 */
@Component<ImageModuleUi>({
  name: 'ImageModuleUi'
})
export class ImageModuleUi extends AbstractModuleUi<ImageModule> {
  public get alignmentClass (): string | null {
    if (!this.style.justifySelf) {
      return null
    }

    return `ImageModuleUi--justify-${this.style.justifySelf}`
  }

  /**
   * Determines whether the component has all the data it needs for a successful render.
   */
  public get shouldRender (): boolean {
    return this.hasContent && !!this.data.content.image
  }

  /**
   * Determines whether module has customClasses
   */
  public get customClass (): Array<string> {
    if (!this.data.content) {
      return []
    }

    if (!this.data.content.customClass || this.data.content.customClass.length === 0) {
      return []
    }

    return this.data.content.customClass
  }

  /**
   * Constructs image props with respect of overridden values.
   */
  public get image (): ImageProps {
    if (!this.data.content.image) {
      throw new Error('ImageModuleUi(): Image is [null]')
    }

    const translatedImage: ImageProps = toImageProps(this.data.content.image)

    const contentCopy: AnyObject = { ...this.data.content }
    delete contentCopy.image

    return {
      ...translatedImage,
      ...contentCopy
    }
  }

  /**
   * Assigns styles based on passed-in props.
   *
   * @example: (max-width, max-height)
   */
  public get sizeStyle (): AnyObject {
    const sizeStyle: AnyObject = {}

    if (typeof this.data.content.maxHeight !== 'undefined') {
      sizeStyle.maxHeight = `${this.data.content.maxHeight}px`
    }

    if (typeof this.data.content.maxWidth !== 'undefined') {
      sizeStyle.maxWidth = `${this.data.content.maxWidth}px`
    }

    return sizeStyle
  }
}

export default ImageModuleUi
