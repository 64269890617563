




















































































































































import { AnyObject } from '@movecloser/front-core'
import { Component, Mixins, Prop, Ref } from 'vue-property-decorator'
import { DebouncedFunc } from 'lodash'
import { ModalConfig } from '@movecloser/front-core/lib/contracts/services'
import debounce from 'lodash/debounce'

import { ImageProps } from '../../../../../dsl/atoms/Image'
import { LinkProps } from '../../../../../dsl/atoms/Link'

import { Inject, logger } from '../../../../../support'

import { ISiteService, NavigationItem, SiteServiceType } from '../../../../../contexts'

import { Drawers } from '../../../../auth/config/modals'
import { Drawers as CheckoutDrawers } from '../../../../checkout/config/modals'
import { BaseCartMixin, IBaseCart } from '../../../../checkout/shared/mixins/base-cart.mixin'

import { ComponentsStructureConfig, StructureConfigurable } from '../../../../../support/mixins'

import { AuthMixin, IAuthMixin } from '../../../../auth/shared'
import { RouteNames } from '../../../../auth/routes'
import { RouteName } from '../../../../checkout/routes'

import {
  FavouriteProductsServiceType,
  IFavouriteProductsService
} from '../../../../products/contracts/services'

import { DrawerType, IDrawer } from '../../../contracts/services'

import { Loader } from '../../../molecules/Loader'

import { SearchResults } from '../../SearchResults'

import { NavbarProps } from '../Navbar.contracts'
import {
  NAVBAR_COMPONENT_KEY,
  NAVBAR_DESKTOP_DEFAULT_CONFIG,
  NavbarTypes,
  StaticLink,
  StaticLinks
} from '../Navbar.config'
import NavbarUser from './NavbarUser.vue'
import BodyMarginMixin from './BodyMargin.mixin.vue'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl> (original)
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl> (edited)
 */
@Component<NavbarDesktop>({
  name: 'NavbarDesktop',
  components: { NavbarUser, SearchResults, Loader },
  created (): void {
    this.config = this.getComponentConfig(
      NAVBAR_COMPONENT_KEY,
      { ...NAVBAR_DESKTOP_DEFAULT_CONFIG }
    )
    this.onMenuEnter = debounce(this.openMenu.bind(this), 200)
    this.onMenuLeave = debounce(this.openClose.bind(this), 10)
  }
})
export class NavbarDesktop extends Mixins<IAuthMixin, IBaseCart, BodyMarginMixin, StructureConfigurable>(
  AuthMixin,
  BaseCartMixin,
  BodyMarginMixin,
  StructureConfigurable
) {
  /**
   * @see NavbarProps.brandImage
   */
  @Prop({ type: Object, required: false })
  public readonly brandImage?: ImageProps

  /**
   * @see NavbarProps.logoLink
   */
  @Prop({ type: Object, required: false })
  public readonly logoLink?: LinkProps

  /**
   * @see NavbarProps.mainLinks
   */
  @Prop({ type: Array, required: false })
  public readonly mainLinks?: NavbarProps['mainLinks']

  /**
   * @see NavbarProps.externalLinks
   */
  @Prop({ type: Array, required: false })
  public readonly externalLinks?: NavbarProps['externalLinks']

  /**
   * Determines user's static routes.
   */
  @Prop({ type: Array, required: true })
  public readonly staticLinks!: Array<StaticLink>

  /**
   * Determines whether topbar is present.
   */
  @Prop({ type: Boolean, required: false, default: false })
  public readonly hasTopBar!: boolean

  /**
   * Determines site's url
   */
  @Prop({
    type: String,
    required: false
  })
  public readonly siteAddress?: string

  @Inject(DrawerType, false)
  private readonly drawerService?: IDrawer

  @Inject(FavouriteProductsServiceType, false)
  protected readonly favouriteProductsService?: IFavouriteProductsService

  @Inject(SiteServiceType, false)
  public readonly siteService?: ISiteService

  /**
   * Reference for navigation.
   */
  @Ref('navigation')
  public readonly navigationRef?: HTMLElement

  public isSearchOpen: boolean = false

  public menuOpen: number | null = null

  /**
   * Determines component config.
   */
  public config: ComponentsStructureConfig = {}

  protected onMenuEnter:
    DebouncedFunc<(
      event: MouseEvent,
      isParent: boolean,
      index: number | null
    ) => void> | null = null

  protected onMenuLeave: DebouncedFunc<(event: MouseEvent, isParent: boolean) => void> | null = null

  /**
   * Get id of account static link.
   */
  public get accountStaticLinkId (): StaticLinks {
    return StaticLinks.account
  }

  public get cartStrategy (): string {
    return this.getConfigProperty('cartStrategy')
  }

  public get favouriteLinkId (): string {
    return StaticLinks.favouriteProducts
  }

  /**
   * Get id of favourite static link.
   */
  public get favouriteStaticLinkId (): StaticLinks {
    return StaticLinks.favouriteProducts
  }

  public get filteredStaticLinks (): StaticLink[] {
    let navigationItems = this.staticLinks

    if (!this.favouriteProductsService) {
      navigationItems = navigationItems.filter(item => {
        return item.id !== this.favouriteLinkId
      })
    }
    return navigationItems
  }

  public get hasBrandImage (): boolean {
    return typeof this.brandImage !== 'undefined'
  }

  /**
   * Determines whether external links are present.
   */
  public get hasExternalLinks (): boolean {
    return typeof this.externalLinks !== 'undefined' && this.externalLinks.length > 0
  }

  public get isNameVisible (): string {
    return this.getConfigProperty('isNameVisible')
  }

  public get loginStrategy (): string {
    return this.getConfigProperty('loginStrategy')
  }

  public get navbarType (): string {
    return this.getConfigProperty('type')
  }

  public get showCartIconWithoutPrice (): boolean {
    return this.getConfigProperty('showCartIconWithoutPrice')
  }

  public get menuOpeningStrategyOnTablet (): string {
    return this.getConfigProperty('menuOpeningStrategyOnTablet')
  }

  public get siteName (): string {
    return this.siteService?.getActiveSite().name || ''
  }

  public get showCartQuantity (): boolean {
    return !!this.cart && this.cart.getTotalQuantity() > 0
  }

  public get withOpenSearchType (): boolean {
    return this.navbarType === NavbarTypes.WithOpenSearch
  }

  public callAction (staticLinkId: string) {
    switch (staticLinkId) {
      case this.favouriteStaticLinkId: {
        this.openDrawerLogin()
        break
      }

      case this.accountStaticLinkId: {
        if (this.loginStrategy === 'drawer') {
          this.openDrawerLogin()
          break
        }

        if (this.loginStrategy === 'router') {
          this.$router.push({ name: `auth.${RouteNames.Auth}` })
        }
        break
      }
      default: {
        logger('[NavbarDesktop]: Action is not defined.', 'debug')
      }
    }
  }

  public handleCartClick (): void {
    if (this.cartStrategy === 'router') {
      this.$router.push({ name: `checkout.${RouteName.Cart}` })
      return
    }

    if (this.cartStrategy === 'drawer') {
      if (typeof this.drawerService === 'undefined') {
        return
      }

      this.drawerService.open(CheckoutDrawers.Cart)
    }
  }

  /**
   * Determines whether current item has children.
   */
  public hasChildren (item: NavigationItem): boolean {
    return Array.isArray(item.children) && item.children.length > 0
  }

  public openMenu (event: MouseEvent, isParent: boolean, index: number | null = null) {
    this.menuOpen = index
    if (this.isSearchOpen) {
      this.isSearchOpen = false
    }
    this.$emit('hover', isParent)
  }

  public openClose (event: MouseEvent, isParent: boolean) {
    this.menuOpen = null
    this.$emit('unhover', isParent)
  }

  /**
   * Handles `om:mouseenter` event of element.
   */
  public onMouseEnter (event: MouseEvent, isParent: boolean, index = null): void {
    if (!this.onMenuEnter) {
      return
    }

    this.onMenuEnter(event, isParent, index)
  }

  /**
   * Handles `om:mouseleave` event of element.
   */
  public onMouseLeave (event: MouseEvent, isParent: boolean): void {
    if (!this.onMenuEnter || !this.onMenuLeave) {
      return
    }

    this.onMenuEnter.cancel()
    this.onMenuLeave(event, isParent)
  }

  /**
   * Open drawer modal
   */
  public openDrawerLogin (payload?: AnyObject, modalConfig?: ModalConfig): void {
    if (!this.drawerService) {
      return
    }

    this.drawerService.open(Drawers.Auth, payload, modalConfig)
  }
}

export default NavbarDesktop
