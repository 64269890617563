
























import { Component, Prop, Ref } from 'vue-property-decorator'

import { LinkProps } from '../../../../../dsl/atoms/Link'

import { ISiteService, Link, SiteServiceType } from '../../../../../contexts'
import { Inject } from '../../../../../support'

import { NavbarProps } from '../Navbar.contracts'
import BodyMarginMixin from './BodyMargin.mixin.vue'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<NavbarSimple>({
  name: 'NavbarSimple'
})
export class NavbarSimple extends BodyMarginMixin {
  /**
   * @see NavbarProps.brandImage
   */
  @Prop({ type: Object, required: false })
  public readonly brandImage?: NavbarProps['brandImage']

  /**
   * @see NavbarProps.brandImage
   */
  @Prop({ type: Object, required: false })
  public readonly brandLink?: NavbarProps['brandLink']

  /**
   * Determines the `backTo` link.
   */
  @Prop({ type: Object, required: false })
  public readonly link?: Link

  /**
   * @see NavbarProps.logoLink
   */
  @Prop({ type: Object, required: false })
  public readonly logoLink?: LinkProps

  @Inject(SiteServiceType, false)
  public readonly siteService?: ISiteService

  /**
   * Reference for navigation.
   */
  @Ref('navigation-simple')
  public readonly navigationRef?: HTMLElement

  /**
   * Determines whether brandImage is present.
   */
  public get hasBrandImage (): boolean {
    return typeof this.brandImage !== 'undefined'
  }

  /**
   * Determines whether `backTo` link is present.
   */
  public get hasLink (): boolean {
    return typeof this.link !== 'undefined' && ('target' in this.link)
  }

  /**
   * Determines active site's url address
   */
  public get siteAddress (): string {
    return this.siteService?.getActiveSiteAddress() || ''
  }
}
export default NavbarSimple
